import React from 'react';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';

// Utilities
import TableFunctions  from '../../utilities/table-functions';

const ProjectsTable = ({ projects, fetchFunc }) => {

    const navigate = useNavigate();

    const rowClick = ( row ) => {
        // Navigate to the single project page
        navigate(`/project/${row.id}/tasks`);
    }

    const filterable_columns = [
        'Name', 'Account Manager'
    ];

    const rowsForDisplay = () => {
        return projects.data.map( project => {
            return {
                ...project,
                account_manager: project.account_manager ? project.account_manager.name : ''
            }
        });
    }

    const changePage = ( page ) => {
        fetchFunc( page );
    }

    return( 
        <DataTable
            columns={TableFunctions.getTableColumns(projects.data, filterable_columns)}
            data={rowsForDisplay(projects.data)}
            highlightOnHover
            pointerOnHover
            onRowClicked={rowClick}
            pagination
            paginationServer
            paginationTotalRows={projects.total}
            onChangePage={changePage}
            paginationPerPage={20}
        />
    );

}

export default ProjectsTable;