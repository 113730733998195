
const TableFunctions = {

    getTableColumns : ( data, filters = [] ) => {
        let omitted_columns = ['id', 'created_at', 'updated_at', 'deleted_at', 'logs', 
            'pivot', 'password', 'remember_token', 'email_verified_at', 'role_id', 'user_id',
            'task_id', 'archived', 'project_id', 'description', 'asana_gid', 'client_email'
        ];
        let columns = Object.keys(data[0]).map( ( column ) => {
            return {
                name: column,
                selector: row => row[column]
            }
        });
        columns = columns.filter( ( column ) => {
            return !omitted_columns.includes(column.name);
        });
        columns = columns.map( ( column ) => {
            column.name = TableFunctions.deSanitize(column.name);
            return column;
        });
        if( filters.length !== 0 ){
            columns = columns.map( ( column, index ) => {
                if( filters.includes(column.name) ){
                    column['sortable'] = true;
                }
                return column;
            });
        }
        return columns;
    },

    deSanitize : ( stringIn ) => {
        let words = stringIn.split('_');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        return words.join( ' ' );
    },

    reOrderColumn : ( array, name, newIndex ) =>{
        // Find the index of the object with the specified key's value
        const currentIndex = array.findIndex(obj => obj['name'] === name);
        
        // If the object is not found, return the original array
        if (currentIndex === -1) {
            return array;
        }
    
        // Remove the object from its current position
        const [objectToMove] = array.splice(currentIndex, 1);
    
        // Insert the object at the new index
        array.splice(newIndex, 0, objectToMove);
    
        return array;
    }

};

export default TableFunctions;