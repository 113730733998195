import React, { createContext, useReducer } from 'react';
import axios from 'axios';

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
}

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
   
    const [state, dispatch] = useReducer(authReducer, {
        isAuthenticated: false,
        user: null,
    });

    return (
        <AuthContext.Provider value={{state, dispatch}}>
            {children}
            </AuthContext.Provider>
    );

}

export const initiateXSRFToken = () => {
    axios.get('/csrf-cookie').then(response => {
        console.log(response);
    });
} 

export function checkAuth(){
    return axios.get('/check-authenticated').then(response => {
        if( response.status === 200 && response.data.authenticated ){
            return response.data;
        }
        return false;
    });
}