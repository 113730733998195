import React, { useState } from 'react';
import axios from 'axios';

// Components
import LinkDialog from './link-dialog';

// TipTap & Extensions
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from '@tiptap/extension-image';
import DropCursor from '@tiptap/pm/dropcursor';

// Material Icons
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import LinkIcon from '@mui/icons-material/Link';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const MenuBar = () => {

  const { editor } = useCurrentEditor();
  const [open, setOpen] = useState(false);
  const [initialUrl, setInitialUrl] = useState('');

  const handleClickOpen = () => {
    const currentLink = editor.getAttributes('link').href || '';
    setInitialUrl(currentLink);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetLink = (url) => {
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  if (!editor) {
    return null
  }

  return (
    <div className="control-group">
      <div className="button-group">
        <button onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
          <FormatBoldIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
          <FormatItalicIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleCode().run()} disabled={!editor.can().chain().focus().toggleCode().run()} className={editor.isActive('code') ? 'is-active' : ''}>
          <CodeIcon />
        </button>
        <button onClick={handleClickOpen} className={editor.isActive('link') ? 'is-active' : ''}>
          <LinkIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
          <span>H1</span>
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
          <span>H2</span>
        </button>
        <button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
          <span>H3</span>
        </button>
        <button onClick={() => editor.chain().focus().setMark('highlight', { color: 'yellow' }).run()} className={editor.isActive('highlight', { color: 'yellow' }) ? 'is-active' : ''}>
          <FormatColorTextIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is-active' : ''}>
          <FormatListBulletedIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'is-active' : ''}>
          <FormatListNumberedIcon />
        </button>
        <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().chain().focus().undo().run()}>
          <UndoIcon />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().chain().focus().redo().run()}>
          <RedoIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleTaskList().run()}>
          <CheckBoxIcon />
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <HorizontalRuleIcon />
        </button>
        <button onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive('blockquote') ? 'is-active' : ''}>
          <FormatQuoteIcon />
        </button>
      </div>
      <LinkDialog open={open} onClose={handleClose} onSetLink={handleSetLink} initialUrl={initialUrl} />
    </div>
  )
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  Highlight.configure({ multicolor: true }),
  Link.configure({ openOnClick: true }),
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  HorizontalRule,
  Image.configure({ inline: true, allowBase64: true }),
  DropCursor
]

export default ({initialValue, onChange, taskId }) => {

  const handleDrop = (view, event, slice, moved) => {
    if (! taskId) {
      // Currently only setup for task level editor
      return false;
    }
    if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      uploadFile( file ).then( response => {
        if( response.data.success ){
          let transaction = placeUploadedImage(response.data, view, event);
          return transaction;
        }
      });
      return true; // handled
    }
    return false; // not handled use default behaviour
  }

  const uploadFile = ( file ) => {
    const formData = new FormData();
    formData.append('files[]', file);
    formData.append('task_id', taskId);
    return axios.post( `/file/create`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
  }

  const placeUploadedImage = (response_data, view, event) => {
    const { schema }    = view.state;
    const coordinates   = view.posAtCoords({ left: event.clientX, top: event.clientY });
    const base64Image   = `data:image/png;base64,${response_data.base64[0]}`;
    const node          = schema.nodes.image.create({ src: base64Image });
    const transaction   = view.state.tr.insert(coordinates.pos, node);
    return view.dispatch(transaction);
  }

  return (
    <EditorProvider 
      slotBefore={<MenuBar />} 
      extensions={extensions} 
      content={initialValue}
      onUpdate={provider => { onChange( provider.editor.getHTML() ); }}
      editorProps={{
        handleDrop: handleDrop
      }}
      >
    </EditorProvider>
  )
}