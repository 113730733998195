import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material Components
import Box from '@mui/material/Box';

// Components 
import UsersTable from './table';

const UsersArchive = () => {

    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        axios.get('/users')
            .then(response => {
                // console.log(response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Box sx={{padding: 1}}>
            <h2>Users</h2>
            {users.length 
                ? <UsersTable users={users} />
                : <p>No users found.</p>
            }
        </Box>
    );

}

export default UsersArchive;