import React, { useState, useEffect } from "react";
import axios from "axios";

// Material Components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const UserSelect = ({ value, onChange, name, label }) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = () => {
        axios.get('/users')
            .then(response => {
                setOptions(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleChange = (event) => {
        onChange(event.target.value);
    }

    return (
        <>
            {options.length > 0 &&
                <FormControl variant="filled" sx={{ marginBottom: 1 }}>
                    <InputLabel>{label}</InputLabel>
                    <MaterialSelect
                        label={label}
                        name={name}
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}
                    </MaterialSelect>
                </FormControl>
            }
        </>
    );

}

export default UserSelect;