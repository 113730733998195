import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components 
import ProjectsTable from './table';
import SearchForm from '../global/search-form';

// Material Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ProjectsArchive = () => {

    const [projects, setProjects] = useState([]);
    const [archived, setArchived] = useState(false);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        fetchProjects();
    }, [ archived, search ] );

    const fetchProjects = ( page ) => {
        let url = getFetchUrl();
        if( page ){
            url += `/?page=${page}`;
        }
        if( search ){
            url += page ? `&search=${search}` : `/?search=${search}`;
        }
        axios.get( url )
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getFetchUrl = () => {
        if( archived ){
            return '/projects/archived';
        }
        return '/projects';
    }

    return (
        <Box sx={{padding: 1}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SearchForm runSearch={(term) => setSearch(term)} />
                <Box>
                    <Button sx={{ margin: 1, float: 'right' }} onClick={() => navigate('/project/new')} variant="outlined">Add Project</Button>
                    {archived 
                        ? <Button sx={{ margin: 1, float: 'right' }} onClick={() => { setArchived(false); } } variant="outlined">View Active</Button>
                        : <Button sx={{ margin: 1, float: 'right' }} onClick={() => { setArchived(true); } } variant="outlined">View Archived</Button>
                    }
                </Box>
            </Box>
            <h1>All Projects</h1>
            {(projects.hasOwnProperty('data') && projects.data.length > 0)
                ? <ProjectsTable projects={projects} fetchFunc={fetchProjects} />
                : <p>No projects found.</p>
            }
        </Box>
    );

}

export default ProjectsArchive;