import React from 'react';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';

// Utilities
import TableFunctions  from '../../utilities/table-functions';

// Material UI Components
import Box from '@mui/material/Box';

const TasksTable = ({ tasks, fetchFunc, showAssigned }) => {

    const navigate = useNavigate();
    const rowClick = ( row ) => {
        // Navigate to the single task page
        navigate(`/task/${row.id}`);
    }

    const priorityOptions = [
        { id: 1, name: 'Low' },
        { id: 2, name: 'Medium' },
        { id: 3, name: 'High' },
        { id: 4, name: 'Critical' }
    ];

    const rowsForDisplay = () => {
        console.log(tasks.data);
        return tasks.data.map( task => {
            delete task.description;
            return {
                ...task,
                priority: task.priority ? priorityOptions.find( option => option.id === task.priority ).name : '--',
                assigned_to: task.assigned_to ? task.assigned_to.name : '--',
                project: task.project ? task.project.name : '--',
            }
        });
    }

    const changePage = ( page ) => {
        fetchFunc( page );
    }

    const getColumns = () => {
        console.log(tasks.data);
        let columns = TableFunctions.getTableColumns(tasks.data);
        console.log(columns);
        if( !showAssigned ){
            // remove assigned_to column
            columns = columns.filter( column => column.name !== 'Assigned To' );
        }
        columns = TableFunctions.reOrderColumn(columns, 'Project', 1);
        // Combine Time Columns
        columns = columns.filter( column => column.name !== 'Estimated Time' && column.name !== 'Time Worked' );
        columns.push({
            name: 'Time',
            selector: row => `${Math.round(row.time_worked * 100 ) / 100} / ${row.estimated_time ? row.estimated_time : '--'}`
        });
        return columns;
    }

    return(
        <Box sx={{ maxWidth: '100%', overflowX: 'scroll' }}>
            <DataTable
                columns={getColumns()}
                data={rowsForDisplay()}
                highlightOnHover
                pointerOnHover
                onRowClicked={rowClick}
                pagination
                paginationServer
                paginationTotalRows={tasks.total}
                onChangePage={changePage}
                paginationPerPage={20}
            />
        </Box>
    );

}

export default TasksTable;