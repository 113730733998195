import React from 'react';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';

// Utilities
import TableFunctions  from '../../utilities/table-functions';

const UsersTable = ({ users }) => {

    const navigate = useNavigate();

    const rowClick = ( row ) => {
        // Navigate to the user's tasks page
        navigate(`/user/${row.id}/tasks`);
    }

    return( 
        <DataTable
            columns={TableFunctions.getTableColumns(users)}
            data={users}
            pagination
            highlightOnHover
            pointerOnHover
            onRowClicked={rowClick}
        />
    );

}

export default UsersTable;