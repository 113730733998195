import React, { useEffect } from 'react';
import axios from 'axios';

// Material Components
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';
import Typography from '@mui/material/Typography';
import { GifBoxTwoTone } from '@mui/icons-material';

const FileRow = ({ file, triggerRefresh }) => {

    useEffect(() => {

    }, []);

    const downloadFile = (fileId) => {
        axios.get( `file/${fileId}/download`, {
            responseType: 'blob'
        }).then((response) => {
                console.log(response.data);
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', file.name); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const deleteFile = (fileId) => {
        axios.post( `file/${fileId}/delete` )
            .then((response) => {
                triggerRefresh();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <Typography sx={{fontSize: '0.8rem', lineHeight: '1'}}>{file.name}</Typography>
            {file.id &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    {file.path &&
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(file.id)}
                            size="small"
                        >
                            <DownloadIcon />
                        </IconButton>
                    }
                    {file.external_url &&
                        <IconButton
                            color="primary"
                            onClick={() => window.open(file.external_url, '_blank')}
                            size="small"
                        >
                            <LinkIcon />
                        </IconButton>
                    }
                    <IconButton
                        color="secondary"
                        onClick={() => deleteFile(file.id)}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            }
        </Box>
    );

}

export default FileRow;