import React, { useState, useEffect } from 'react';
import axios from 'axios';

import FileRow from './file-row';

// Material Components
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUploader = ({ triggerUpload, taskId }) => {

    const [files, setFiles] = useState({});
    const [existingFiles, setExistingFiles] = useState([]);

    useEffect(() => {
        getExistingFiles();
    }, []);

    useEffect(() => {
        if( true !== triggerUpload ){
            return;
        }
        if (files.length > 0) {
            uploadFiles();
        }
    }, [triggerUpload]);

    const inputChange = (e) => {
        setFiles( e.target.files );
    }

    const uploadFiles = () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            formData.append('files['+ i +']', files[i]);
        }
        formData.append('task_id', taskId);
        axios.post( `/file/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            getExistingFiles();
        }).catch((error) => {
            console.log(error);
        });
    }

    const getExistingFiles = () => {
        axios.get(`/task/${taskId}/files`).then((response) => {
            setExistingFiles(response.data);
            setFiles({});
        }).catch((error) => {
            console.log(error);
        });
    }
    
    return (
        <>
            <Typography variant="h6">Files</Typography>
            <Button
                sx={{ marginBottom: 2 }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                >
                    Upload file
                <VisuallyHiddenInput type="file" onChange={inputChange} multiple />
            </Button>

            {Object.keys(existingFiles).map((key) => {
                const file = existingFiles[key];
                return <FileRow key={key} file={file} triggerRefresh={getExistingFiles} />
            })}
            {Object.keys(files).map((key) => {
                const file = files[key];
                return <Typography key={key} sx={{fontSize: '0.8rem', lineHeight: '2'}}>{file.name}</Typography>
            })}
        </>
    );

}

export default FileUploader;