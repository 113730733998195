import React, { useEffect } from 'react';

// Material Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SingleComment = ({ comment }) => {

    useEffect(() => {
        console.log(comment);
    }, []);

    const displayDate = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()} ${timeFormat(d)}`;
    }

    const timeFormat = (d) => {
        if( d.getHours() > 12 ) {
            return `${d.getHours() - 12}:${d.getMinutes()} PM`;
        }
        return `${d.getHours()}:${d.getMinutes()} AM`;
    }

    return (
        <Box sx={{
            padding: 2,
            backgroundColor: '#f9f9f9',
            marginBottom: 1
        }}>
            <Typography sx={{margin: 0, fontWeight: 'bold'}}>{comment.user.name}</Typography>
            <Typography sx={{margin: 0, fontSize: '12px', color: '#777777'}}>{displayDate(comment.created_at)}</Typography>
            <Typography sx={{marginTop: 1, fontSize: '14px' }} dangerouslySetInnerHTML={{__html: comment.comment}}></Typography>
        </Box>
    );

}

export default SingleComment;