import React, { useEffect, useState, useContext, useReducer } from 'react';
import axios from 'axios';

// Material Components
import Button from '@mui/material/Button';

// Components
import { AuthContext } from '../../utilities/auth-context';
import SingleComment from './single-comment';
import TextEditor from '../global/text-editor';

const CommentsDisplay = ({ taskId }) => {

    const [comments, setComments]       = useState([]);
    const [newComment, setNewComment]   = useState('');
    const { state }                     = useContext(AuthContext);
    const [forceCounter, forceUpdate]   = useState(0);

    useEffect(() => {
        console.log( state );
        getComments();
    }, []);

    const getComments = () => {
        axios.get(`/task/${taskId}/comments`)
        .then((response) => {
            setComments(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const postComment = () => {
        if( ! newComment.length ){
            return;
        }
        axios.post(`/comment/create`, {
            task_id: taskId,
            user_id: state.user,
            comment: newComment
        })
        .then((response) => {
            setNewComment('');
            getComments();
            forceUpdate( forceCounter + 1 );
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <h2>Comments</h2>
            {comments.map((comment) => {
                return <SingleComment key={comment.id} comment={comment} />
            })}
            <TextEditor key={forceCounter} initialValue={''} onChange={ (val) => { setNewComment(val); } } /> 
            <Button variant="contained" color="secondary" onClick={postComment}>Post</Button>
        </>
    );

}

export default CommentsDisplay;