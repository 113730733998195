import React, {useState} from 'react';

// Date Picker
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const DateField = ({ label, value, onChange }) => {

    const [open, setOpen] = useState(false);

    return (
        <FormControl variant="filled" sx={{ marginBottom: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    label={label}
                    value={value ? dayjs(value) : null}
                    onChange={(val) => onChange(val.format('YYYY-MM-DD'))}
                    slotProps={{
                        textField: {
                            variant: 'filled',
                            onClick: () => setOpen(true)
                        }
                    }}
                />
            </LocalizationProvider>
        </FormControl>
    );

}

export default DateField;