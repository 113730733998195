import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Box } from '@mui/material';

const LinkDialog = ({ open, onClose, onSetLink, initialUrl }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (open) {
      setUrl(initialUrl || '');
    }
  }, [open, initialUrl]);

  const handleSetLink = () => {
    if (url) {
      onSetLink(url);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Enter the URL</DialogTitle>
        <DialogContent>
            <Box>
                <TextField
                autoFocus
                margin="dense"
                label="URL"
                type="url"
                fullWidth
                variant="standard"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSetLink}>Set Link</Button>
        </DialogActions>
    </Dialog>
  );
};

export default LinkDialog;