import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Auth Context
import { AuthContext } from '../../utilities/auth-context';

// Components
import RecentProjects from './recent-projects';

// Material Components
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Icons
import AssignmentLate from '@mui/icons-material/AssignmentLate';
import Code from '@mui/icons-material/Code';
import Person from '@mui/icons-material/Person';
import Logout from '@mui/icons-material/Logout';

const MainMenu = ({ onLogout }) => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { dispatch } = useContext(AuthContext);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    
    const handleLogout = (event) => {
        axios.post('/logout').then(response => {
            if( response.status === 200 ){
                dispatch({ type: 'LOGOUT' });
                onLogout();
                navigate('/login');
            }
        });
    }

    const links = [
        { text: 'My Tasks', path: '/my-tasks', icon: AssignmentLate },
        { text: 'All Projects', path: '/projects', icon: Code },
        { text: 'Users', path: '/users', icon: Person },
    ];
    const actions = [
        { text: 'Logout', icon: Logout, func: handleLogout }
    ];

    return (
        <Box sx={{ width: 250, minWidth: 250 }} role="presentation" onClick={toggleDrawer(false)}>          
            <List>
                {links.map((link, index) => (
                    <ListItem key={link.text} onClick={ e => navigate(link.path)} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <link.icon />
                            </ListItemIcon>
                            <ListItemText primary={link.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
                {actions.map((action, index) => (
                    <ListItem key={action.text} onClick={action.func} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <action.icon />
                            </ListItemIcon>
                            <ListItemText primary={action.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <RecentProjects />
        </Box>
    );

}

export default MainMenu;