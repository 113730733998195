import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material Components
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

// Components
import AmSelect from '../global/user-select';

const SingleProject = () => {

    const { projectId } = useParams();
    const [project, setProject] = useState({
        id: null,
        name: null,
        account_manager: null,
        budget: 0,
        rate: 150,
        client_email: '',
        archived: 0
    });
    const [error, setError] = useState('');
    const [saved, setSaved] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if( projectId ){
            getProject();
        }
    }, []);

    // Logging Project Changes
    useEffect(() => {
        console.log(project);
    }, [ project ]);

    const getProject = () => {
        axios.get(`/project/${projectId}`)
        .then(response => {
            setProject(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const saveProject = () => {
        if( project.id ){
            updateProject();
        }
        else{
            createProject();
        }
    }

    const updateProject = () => {
        axios.post(`/project/${projectId}/update`, project)
            .then(response => {
                if( response.data.error ){
                    setError(response.data.error);
                }
                else{
                    setSaved(true);
                    setTimeout(() => {
                        setSaved(false);
                    }, 3000);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const createProject = () => {
        axios.post(`/project/create`, project)
            .then(response => {
                if( response.data.error ){
                    setError(response.data.error);
                }
                else{
                    setProject(response.data);
                    setSaved(true);
                    setTimeout(() => {
                        setSaved(false);
                    }, 3000);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div>
            {(project.id || !projectId) &&
                <Box
                    maxWidth='sm'
                    sx={{
                        marginTop: 2,
                        padding: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
                        {projectId &&
                            <Link component="button" variant="body2" onClick={e => navigate(`/project/${projectId}/tasks`)}>
                                {project.name} Tasks
                            </Link>
                        }
                    </Box>
                    {project.name 
                        ? <h1>{project.id} {project.name}</h1>
                        : <h1>New Project</h1>
                    }
                    {saved && <Alert severity="success" sx={{marginBottom: 1}}>Project Saved</Alert>}
                    {error && <Alert severity="error" sx={{marginBottom: 1}}>{error}</Alert>}
                    <TextField 
                        id="name" 
                        label="Name" 
                        variant="filled" 
                        value={project.name} 
                        onChange={(e) => setProject( { ...project,  name: e.target.value } )}
                        sx={{ marginBottom: 1 }}
                    />
                    <AmSelect 
                        value={project.account_manager} 
                        name='account_manager'
                        label='Account Manager'
                        onChange={(val) => setProject( { ...project, account_manager: val } )}
                    />
                    <TextField 
                        id="budget" 
                        label="Budget" 
                        variant="filled" 
                        value={project.budget} 
                        onChange={(e) => setProject( { ...project,  budget: e.target.value } )}
                        type='number'
                        sx={{ marginBottom: 1 }}
                    />
                    <FormControl variant="filled" sx={{ marginBottom: 1 }}>
                        <RadioGroup name="type" value={project.type} onChange={(e) => setProject( { ...project,  type: e.target.value } )}>
                            <FormControlLabel value="Fixed" control={<Radio />} label="Fixed" checked={project.type === 'Fixed'}/>
                            <FormControlLabel value="Hourly" control={<Radio />} label="Hourly" checked={project.type === 'Hourly'} />
                        </RadioGroup>
                    </FormControl>
                    {'Hourly' == project.type &&
                        <TextField 
                            id="rate" 
                            label="Rate" 
                            variant="filled" 
                            value={project.rate} 
                            onChange={(e) => setProject( { ...project,  rate: e.target.value } )}
                            type='number'
                            sx={{ marginBottom: 1 }}
                        />
                    }
                    <TextField 
                        id="client_email" 
                        label="Client Email" 
                        variant="filled" 
                        value={project.client_email} 
                        onChange={(e) => setProject( { ...project,  client_email: e.target.value } )}
                        sx={{ marginBottom: 1 }}
                    />
                    <FormControl sx={{ marginBottom: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={project.archived == 1}
                                    onChange={(e) => setProject( { ...project,  archived: e.target.checked ? 1 : 0 } )}
                                    name="archived"
                                />
                            }
                            label="Archived"
                        />
                    </FormControl>
                    <Button variant="contained" color="secondary" onClick={saveProject}>Save</Button>
                </Box>
            }
        </div>
    );

}

export default SingleProject;