import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

// Material Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const RecentProjects = () => {

    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch recent projects
        axios.get('/history/recent-projects').then(response => {
            console.log('recents => ', response);
            if( response.status === 200 ){
                setProjects(response.data);
            }
        });
    }, []);

    return (
        <>
            {projects.length > 0 && 
                <>
                    <Typography sx={{marginTop: 3, paddingLeft: 2, fontWeight: 'bold'}}>Recent Projects</Typography>
                    <List>
                        {projects.map((project, index) => (
                            <ListItem key={project.id}>
                                <Link component="button" sx={{ textAlign: 'left' }} variant="body2" onClick={ e => navigate(`/project/${project.id}/tasks`)}>
                                    {project.name}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </>
            }
        </>
    );

}

export default RecentProjects;