import { useContext, useEffect } from 'react';
import { AuthContext, checkAuth, initiateXSRFToken } from '../../utilities/auth-context';

const CheckAuth = ({ onAuthenticate }) => {

    const { dispatch } = useContext(AuthContext);

    useEffect(() => {
        checkAuth().then(data => {
            if( false === data ){
                initiateXSRFToken();
                window.location.href = '#/login';
            } else {
                dispatch({ type: 'LOGIN', payload: data.user });
                onAuthenticate();
            }
        });
    }, []);

    return null;

};

export default CheckAuth;