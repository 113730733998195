import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';

// Components
import LogsTable from './table';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// import Button from '@mui/material';

const LogsArchive = () => {

    const { taskId }                = useParams();
    const [logs, setLogs]           = useState([]);
    const [taskName, setTaskName]   = useState('');
    const [msg, setMsg]             = useState('Loading...');
    const navigate                  = useNavigate();

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = () => {
        axios.get(`/task/${taskId}/logs`)
            .then(response => {
                console.log(response.data);
                if( response.data.logs.length === 0 ){
                    setMsg('No logs found.');
                }
                setLogs(response.data.logs);
                setTaskName(response.data.task_name);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Box sx={{padding: 1}}>
            <Box sx={{marginTop: 2}}>
                <Link component="button" variant="body2" onClick={e => navigate(`/task/${taskId}`)}>
                    Back to Task
                </Link>
                <h1>Time Logs: {taskName}</h1>
            </Box>
            <LogsTable logs={logs} taskId={taskId} triggerReload={getLogs} />
            {logs.length == 0 &&
                <p>{msg}</p>
            }
        </Box>
    );

}

export default LogsArchive;